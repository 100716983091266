/**
 * Gets a single parameter from the url
 */
export function getUrlParam(curWindow: Window, param: string): any | undefined {
  const queryString = curWindow.location.search;
  const urlParams = new URLSearchParams(queryString);
  if (urlParams.has(param) === false) return;
  const value = urlParams.get(param) ?? undefined;
  // if the param value seems to be an array or object, parse it as json
  if (value?.startsWith("[") || value?.startsWith("{")) {
    return JSON.parse(value);
  } else {
    return value;
  }
}

/**
 * Gets all of the parameters as their represented on the url
 */
export function getFullUrlParamString(curWindow: Window): string {
  const queryString = curWindow.location.search;
  const urlParams = new URLSearchParams(queryString);
  return urlParams.toString();
}

/**
 * Sets a single parameter in the url, if the value is undefined it will delete this parameter
 */
export function setUrlParam<t>(curWindow: Window, param: string, value?: t) {
  const url = new URL(curWindow.location.toString());
  if (!value) {
    url.searchParams.delete(param);
  } else {
    url.searchParams.set(param, JSON.stringify(value));
  }
  curWindow.history.pushState({}, "", url.toString());
}

/**
 * Clear all of the search parameters on the url
 */
export function clearUrlParams(curWindow: Window) {
  const queryString = curWindow.location.search;
  const urlParams = new URLSearchParams(queryString);
  const url = new URL(curWindow.location.toString());
  const params = Array.from(urlParams.keys());
  params.forEach((param) => url.searchParams.delete(param));
  curWindow.history.pushState({}, "", url.toString());
}

/**
 * Get all of the url parameters as an object of type any
 */
export function getUrlParamsAsObj<T>(curWindow: Window): T {
  const urlParams = new URLSearchParams(curWindow.location.search);
  const keys = Array.from(urlParams.keys());
  const kvPairs = new Map(keys.map((key) => [key, getUrlParam(window, key)]));
  return Object.fromEntries(kvPairs) as unknown as T;
}

/**
 * Take an object of type any and return a string of all of the key value
 * pairs formatted as url parameters
 */
export function objToParamString(obj: any): string {
  const urlParams = new URLSearchParams();
  for (const [name, value] of Object.entries(obj)) {
    if (value !== undefined) {
      urlParams.set(name, JSON.stringify(value));
    }
  }

  return urlParams.toString();
}
