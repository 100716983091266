import { getUrlParam } from "../utils/url-param-utils";
import { useUser } from "context/UserContext";
import { useRouter } from "next/router";

export default function Home(): JSX.Element | null {
  const user = useUser();
  const router = useRouter();

  // If a user has signed in, redirect to the monitors page
  if (user) {
    router.push("/monitors");
    return null;
  }

  const inviteId = getUrlParam(window, "invite");
  router.push(inviteId ? `/signin?invite=${inviteId}` : "/signin");
  return null;
}
